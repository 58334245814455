.foot-section {
    position: sticky;
	bottom: 0;
	box-sizing: border-box;
	width: 100%;
	padding: 5px;

	font-size: 12px;
	font-weight: 500;
	color: #9190a5;
	text-align: center;
	background: #081624;
	border-top: 1px solid #b01ba5;
}

.foot-section a {
	text-decoration: none;
	outline: none;
	color: #9190a5;
}

.foot-section a:hover {
	text-decoration: underline;
}