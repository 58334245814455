.menu-section {
	position: sticky;
	top: 0;
	box-sizing: border-box;
	width: 100%;
	padding: 0.5em;

	background: #081624;
	border-bottom: 1px solid #b01ba5;

	display: flex;
	align-items: stretch;
	justify-content: space-between;
}

.menu-section > div {
	display: flex;
	align-items: stretch;
	gap: 1.5em;
}

.menu-section a {
	text-decoration: none;
	outline: none;
}

.menu-entry {
	position: relative;
	display: flex;
	align-items: center;
	color: #ddd;
	font-size: 16px;
	cursor: pointer;
}

.menu-entry:hover {
	color: #b01ba5;
}

.menu-entry:hover nav {
	display: flex;
	gap: 0.2em;
}

.menu-entry:hover .menu-rotate {
	transform: rotate(180deg);
}

.menu-entry label {
	cursor: pointer;
	font-weight: bold;
}

.menu-entry nav {
	position: absolute;
	right: 0;
	top: 100%;
	padding: 0.5em;
	display: none;
	flex-direction: column;
	text-align: left;
	background: #081624;
	border-left: 0.1em solid #b01ba5;
	border-right: 0.1em solid #b01ba5;
	border-bottom: 0.1em solid #b01ba5;
	border-radius: 0 0 1em 1em;
}

.menu-entry a {
	color: #ddd;
	font-weight: bold;
}

.menu-entry a:hover {
	color: #b01ba5;
}

.menu-section svg {
	height: 1.5em;
	width: 1.5em;
	margin-right: 0.25em;
}

/* Large Mobile :480px. */

@media only screen and (max-width: 767px) {
	.menu-entry label {
		display: none;
	}
}