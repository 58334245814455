.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-body {
  flex-grow: 1;
  overflow: auto;
  /*background: linear-gradient(to right, #330d38 0%, #190d36 100%);*/
  background: linear-gradient(135deg, #2E22AC 0%, #E54C9C 100%);
}