.logotext {
    font-family: 'ALGER';
    text-transform: uppercase;
    color: #b01ba5;

    text-decoration: none;
	outline: none;

    display: inline-block;
}

.logotext div:nth-child(1) {
    font-size: 30px;
    line-height: 1em;
}

.logotext div:nth-child(1) i {
    color: #ddd;
}

.logotext div:nth-child(2) {
    font-size: 15px;
    line-height: 1em;
    display: flex;
}

.logotext div:nth-child(2) i {
    background-color: #b01ba550;
}

.logotext mark {
    flex-grow: 1;
    background-color: transparent;  /*remove default*/
    background-image: linear-gradient(to right, transparent, #b01ba550);
}

.logotext i {
    font-style: normal; /*remove default*/
}